import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import "@/assets/css/tailwind.css";
import "./css/main.css";
import "vue-toastification/dist/index.css";
import getEnv from "@/utils/getEnv";
import rg4js, { RaygunPayload } from "raygun4js";
import i18n from "./i18n";
import { configureClient } from "./api/client";
import ContractNode from "@/components/contractEditor/ContractNode.vue";
import TemplateNode from "@/admin/components/templateEditor/TemplateNode.vue";
import Tree from "@/components/tree/Tree.vue";
import "@/firefox-shim.js";
import Toast, { PluginOptions } from "vue-toastification";
import { removeSensitiveData } from "./utils/loggingHelper";
import directives from "./directives/directives";
import { measureClientTextWidth } from "./utils/clientCapabilitiesHelper";

const options: PluginOptions = {
  icon: false,
  closeButton: false,
  closeOnClick: false,
  toastClassName:
    "!p-0 !max-w-sm !w-full !bg-white !shadow-lg !rounded-lg !pointer-events-auto !ring-1 !ring-black !ring-opacity-5 !overflow-hidden"
};

const onBeforeRaygunSend = (payload: RaygunPayload) => {
  return removeSensitiveData(payload);
};

const raygunApiKey = getEnv("RAYGUNSETTINGS__APIKEY");
if (raygunApiKey) {
  rg4js("apiKey", raygunApiKey);
  rg4js("setFilterScope", "all");
  rg4js("filterSensitiveData", [
    "username",
    "email",
    "password",
    "token",
    "search",
    "text"
  ]);
  rg4js("enableCrashReporting", true);
  rg4js("onBeforeSend", onBeforeRaygunSend);
  rg4js("groupingKey", function (payload: RaygunPayload) {
    if (
      payload &&
      payload.Details &&
      payload.Details.Error &&
      payload.Details.Error.Message
    ) {
      const errorMessage = payload.Details.Error.Message;
      if (errorMessage) {
        const statusCodeMatch = errorMessage.match(/status code (\d+)/);
        if (statusCodeMatch && statusCodeMatch[1]) {
          const statusCode = statusCodeMatch[1];
          return "RequestFailedWithStatusCode_" + statusCode;
        }
      }
    }
    // Fall back to default grouping if the error doesn't match the expected format
    return;
  });
}

const app = createApp(App);

window.onerror = function (message, source, lineno, colno, error) {
  rg4js("send", {
    error: error
  });
};

app.config.errorHandler = function (err, instance, info) {
  rg4js("send", {
    error: err,
    customData: [{ info: info }]
  });
};

app.provide(
  "clientTextScaling",
  measureClientTextWidth(
    "abcdefghijklmnopqrstuvwxyzåäöABCDEFGHIJKLMNOPQRSTUVWXYZÅÄÖ1234567890,.;:-_ ",
    "0.7rem Lineto-brown; 0.7rem Helvetica; 0.7rem Arial; 0.7rem sans-serif"
  ) / 472
);

app.use(i18n);
app.use(router);
app.use(Toast, options);
app.component("TemplateNode", TemplateNode);
app.component("ContractNode", ContractNode);
app.component("Tree", Tree);
app.directive("autofocus", directives.autofocus);
configureClient();

app.mount("#app");
